

























import { placeModule } from '@/store';
import PlaceModule from '@/store/place';
import { Vue, Component, Watch } from 'vue-property-decorator';

@Component({
  head: {
    title() {
      return {
        inner: '개인정보 제공 업체 목록'
      }
    }
  }
})
export default class Store extends Vue {
  public page: number = 1;
  public maxPage: number = 9;
  public params = {
    is_map: true,
    offset: 1,
    limit: 20
  };
  public created() {
    this.fetchData();
  }
  public fetchData() {
    placeModule
      .fetchPrivacyPlaceList(this.params)
      .then((res: any) => {
        const max = Math.ceil(res.meta.count / 20);
        this.maxPage = max;
      })
  }
  @Watch('page')
  public pageUpdate() {
    this.params.offset = this.page;
    this.fetchData();
  }

  get isApp() {
    if (this.$route.query._from) return true;
    else return false;
  }
  get privacyPlaceList() {
    return placeModule.privacyPlaceList;
  }
}
